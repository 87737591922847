@import url(https://fonts.googleapis.com/css?family=Gugi);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
    height: 100vh;
    width: 100%;
    background-image: url(/static/media/background.3919cca9.jpg);
    background-repeat: no-repeat;
}

.container {
    width: 100%;
    text-align: center;
}

h1 {
    color: whitesmoke;
    -webkit-text-fill-color: rgba(222, 222, 222, 0.65); /* Will override color (regardless of order) */
    -webkit-text-stroke-width: 3px;
    -webkit-text-stroke-color: rgba(55, 55, 55, 0.95);
    font-size: 7.5rem;
    font-family: 'Gugi', cursive;
    text-transform: uppercase;
}

h3 {
    color: whitesmoke;
    -webkit-text-fill-color: rgba(222, 222, 222, 0.65); /* Will override color (regardless of order) */
    -webkit-text-stroke-width: 3px;
    -webkit-text-stroke-color: rgba(55, 55, 55, 0.95);
    font-size: 2.5rem;
}

input.urlInput {
    width: 40%;
    border: none;
    border-radius: 5px 0 0 5px;
    padding: 10px;
    line-height: 3.4rem;
    font-size: 2rem;
    font-family: 'Gugi', cursive;
    background: rgba(255, 255, 255, 0.75)
}

button.urlButton {
    width: 7%;
    background-color: rgba(177, 177, 177, 0.88);
    border: none;
    border-radius: 0 5px 5px 0;
    line-height: 3.4rem;
    font-size: 2rem;
    font-family: 'Gugi', cursive;
    padding: 10px;
    -webkit-text-fill-color: rgba(222, 222, 222, 0.65); /* Will override color (regardless of order) */
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: rgba(55, 55, 55, 0.95);
    cursor: pointer;
}

button.urlButton:hover {
    line-height: calc(3.4rem - 2px);
    background-color: rgba(200, 200, 200, 0.77);
    border: 1px solid rgba(44, 44, 44, 0.75);
    border-left: none;
}

.shortBox {
    width: 50%;
    height: 150px;
    background: rgba(175, 175, 175, 0.85);
    margin: 0 auto;
}
